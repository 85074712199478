<template>
  <div class="map-container" :id="leafletContainerId">
    Loading Map...
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-measure-path/leaflet-measure-path';
import 'leaflet-measure-path/leaflet-measure-path.css';

import markerIconUrl from 'leaflet/dist/images/marker-icon.png';

import cssVars from 'css-vars-ponyfill/dist/css-vars-ponyfill';

import Constants from '@/store/Constants';

const {
  MapToolsStore: MTS,
} = Constants;

L.Marker.prototype.options.icon = L.icon({
  iconAnchor: [13, 41],
  iconUrl: markerIconUrl,
  shadowAnchor: [5, 43],
});

export default {
  computed: {
    ...mapGetters({
      ToolTypes: `${MTS.Name}/${MTS.Getters.ToolTypes}`,
    }),
    baseType: () => 'vector-green',
    countryCenter: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [
      [-34.8191663551, 16.3449768409],
      [-22.0913127581, 32.830120477],
    ],
    leafletContainerId: () => 'leaflet-container-id',
    markerIcon() {
      return !L
        ? {}
        : L.icon({
          iconAnchor: new L.Point(13, 41),
          iconUrl: markerIconUrl,
        });
    },
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      marker: null,
      polylines: [],
    };
  },
  methods: {
    ...mapMutations({
      SetSelectedTool: `${MTS.Name}/${MTS.Mutations.SetSelectedTool}`,
    }),
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    initLeafletDraw() {
      const featureGroup = new L.FeatureGroup();
      const options = {
        draw: {
          circle: {
            metric: true,
            shapeOptions: {
              color: '#0758A3',
            },
          },
          circlemarker: false,
          rectangle: {
            metric: true,
            shapeOptions: {
              color: '#0758A3',
            },
          },
          polyline: {
            metric: true,
            shapeOptions: {
              color: '#0758A3',
            },
          },
          polygon: {
            metric: true,
            allowIntersection: false,
            drawError: {
              color: '#e1e100',
              message: "You can't draw that!",
            },
            shapeOptions: {
              color: '#0758A3',
            },
          },
          marker: false,
        },
        edit: {
          featureGroup,
          remove: true,
          edit: false,
        },
      };
      this.leafletMap.addLayer(featureGroup);
      const layerInfoLeafletDrawControl = new L.Control.Draw(options);
      this.leafletMap.addControl(layerInfoLeafletDrawControl);
      this.leafletMap.on(L.Draw.Event.CREATED, (e) => {
        featureGroup.addLayer(e.layer);
        const { layer } = e;
        layer.showMeasurements();
      });
      this.leafletMap.on(L.Draw.Event.DRAWSTART, (e) => {
        const { layerType } = e;
        const type = this.ToolTypes.find((t) => t.value === layerType);
        this.SetSelectedTool(type);
      });
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }
      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.countryCenter),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.baseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 6,
        zoomControl: true,
      });

      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      this.initLeafletDraw();
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
    cssVars();
  },
  name: 'LeafletMap',
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
  },
};
</script>
<style src="leaflet-draw/dist/leaflet.draw.css"></style>
<style>
.leaflet-draw-section a {
    background-color: #addbe5!important;
    color: #fff !important;
}
.leaflet-draw-actions a {
    background-color: var(--primary) !important;
    color: #fff !important;
}
.leaflet-bar.leaflet-control-zoom a{
  background-color: var(--primary) !important;
  color: #fff !important;
}
.leaflet-draw-toolbar a {
  background-image: url('https://unpkg.com/leaflet-draw@1.0.2/dist/images/spritesheet.svg') !important;
}
.leaflet-measure-path-measurement {
    font-size: 16px !important;
}
.leaflet-popup-content p {
  margin: 10px 0 !important;
}
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
</style>
