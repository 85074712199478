export default {
  ACTIONS: {
    LOAD: 'Load',
  },

  GETTERS: {
    DATA: 'Data',
    IS_LOADING: 'IsLoading',
  },

  MUTATIONS: {
    SET_DATA: 'SetData',
    SET_IS_LOADING: 'SetIsLoading',
    RESET: 'Reset',
  },

  STATE_VARS: {
    DATA: 'data',
    IS_LOADING: 'isLoading',
  },

  MapToolsStore: {
    Getters: {
      ToolTypes: 'ToolTypes',
      SelectedTool: 'SelectedTool',
    },
    Mutations: {
      SetSelectedTool: 'SetSelectedTool',
    },
    Name: 'mapToolsStore',
    StateVars: {
      ToolTypes: 'toolTypes',
      SelectedTool: 'selectedTool',
    },
  },
};
