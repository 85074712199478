<template>
  <div id="app">
    <b-alert
      class="container container-between-stickies"
      show
      variant="info"
      v-if="!OidcUser"
    >
      <b-spinner label="Loading..." small />
      Loading User Information
    </b-alert>
    <router-view class="container-between-stickies" v-else />
  </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { BAlert, BSpinner } from 'bootstrap-vue';

import { AuthMixin } from '@afrigis/iris-vuex-auth-store';

export default {
  components: {
    BAlert,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      OidcUser: 'authStore/OidcUser',
    }),
  },
  methods: {
    ...mapActions({
      AuditWrite: 'auditStore/Log',
    }),
  },
  mounted() {
    this.AuditWrite(this.OidcUser.email);
  },
  mixins: [AuthMixin],
};
</script>

<style>
#share-app-fallback {
  pointer-events: none;
  background-color: lightgrey;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
  .contactAExpert {
    display: none;
  }
}
</style>
