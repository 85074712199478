import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

import Constants from '@/store/Constants';

const {
  MapToolsStore: MTS,
} = Constants;

const actions = {
};

const initialState = () => ({
  [MTS.StateVars.ToolTypes]: [
    {
      name: 'Distance Measurement Tool',
      description: 'This tool is mostly used to measure the estimated distance between two or more points. The measurement adds up as you add more points.',
      value: 'polyline',
    },
    {
      name: 'Polygon',
      description: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      value: 'polygon',
    },
    {
      name: 'Rectangle',
      description: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      value: 'rectangle',
    },
    {
      name: 'Circle',
      description: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      value: 'circle',
    },
  ],
  [MTS.StateVars.SelectedTool]: null,
});

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, Object.values(MTS.StateVars)),
};

const mutations = {
  ...MutationsUtility.scaffold(state, Object.values(MTS.StateVars)),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
