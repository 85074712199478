<template>
  <div
    id="trip-cost-description"
  >
    <b-card
      id="trust-us-card"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      text-variant="white"
    >
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card
      id="possible-uses-card"
    >
      <h5>Possible uses:</h5>
      <ul>
        <li>Reveals valuable insights during initial stages of planning.</li>
        <li>Measure the estimated distance between points (straight line distance).</li>
        <li>Measure estimated area size of a polygon or shape.</li>
      </ul>
    </b-card>
    <b-card
      id="possible-uses-card"
      v-if="SelectedTool"
      class="d-none"
    >
      <h5>Map Tools</h5>
      <p class="font-weight-bold mb-1">{{ SelectedTool.name }}</p>
      <p>{{ SelectedTool.description }}</p>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BCard,
} from 'bootstrap-vue';

import Constants from '@/store/Constants';

const { MapToolsStore: MTS } = Constants;

const backgroundImage = require('@/assets/info_bg.png');

export default {
  components: {
    BCard,
  },
  computed: {
    ...mapGetters({
      SelectedTool: `${MTS.Name}/${MTS.Getters.SelectedTool}`,
    }),
    backgroundImage: () => backgroundImage,
  },
  name: 'Information',
};
</script>

<style scoped>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;

  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

#possible-uses-card {
  border-top: 0;
  border-color: white;
}
</style>
